import React, { useState } from "react";
import PrimaryButton from "../../../common/primaryButton/PrimaryButton";
import { NavLink, useLocation } from "react-router-dom";
import { arrowLeft } from "../../../common/images";
import Lottie from "lottie-react";
import animation from "../../../assets/animations/authAnimation.json";
import { forgotService } from "../../../services/login-service";
import { toast } from "react-toastify";

const CheckMail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const email = state?.email;

  const handleResend = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { status } = await forgotService(email);
    setIsLoading(false);
    if (status) {
      toast.dismiss();
      toast.success("Email successfully sent");
    }
  };

  return (
    <div className="auth-content forgot check-mail">
      <div className="left-content">
        <div className="content">
          <form>
            <h3>Check your email</h3>
            <p>We sent a password reset link to {email}</p>
            <PrimaryButton btnText="Open email app" noBtnIcon type={'button'} style={{cursor:'default'}}/>
            <p className="resend">
              Didn’t receive the email?{" "}
              <NavLink
                onClick={handleResend}
                style={{ pointerEvents: isLoading ? "none" : "auto",  }}
              >
                Click to resend
              </NavLink>
            </p>
            <NavLink to="/login">
              <img src={arrowLeft} alt="" />
              Back to log in
            </NavLink>
          </form>
        </div>
      </div>
      <div className="right-content">
        <div className="auth-animation">
          <Lottie animationData={animation} />
        </div>
      </div>
    </div>
  );
};

export default CheckMail;
