import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { arrowLeft } from "../../../common/images";
import PrimaryButton from "../../../common/primaryButton/PrimaryButton";
import "./Forgot.scss";
import Lottie from "lottie-react";
import animation from "../../../assets/animations/authAnimation.json";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotSchema } from "./helper";
import { forgotService } from "./../../../services/login-service";

const Forgot = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotSchema),
  });

  const onSubmitForm = async (values) => {
    setIsLoading(true);
    const { status, error } = await forgotService(values.EmailAddress);
    setIsLoading(false);
    if (status) {
      navigate("/check-email", { state: { email: values.EmailAddress } });
    }
    if (error) {
    }
  };

  return (
    <div className="auth-content forgot">
      <div className="left-content">
        <div className="content">
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <h3>Forgot password?</h3>
            <p>No worries, we’ll send you reset instructions.</p>
            <div className="input">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="text"
                placeholder="Enter your email"
                {...register("EmailAddress")}
                className={errors?.EmailAddress && "error-input"}
              />
              {errors.EmailAddress?.message && (
                <span className="error" style={{ textAlign: "left" }}>
                  {errors.EmailAddress?.message}
                </span>
              )}
            </div>
            <PrimaryButton
              btnText={isLoading ? "Loading..." : "Reset password"}
              noBtnIcon
              disabled={isLoading}
            />
            <NavLink to="/login">
              <img src={arrowLeft} alt="" />
              Back to log in
            </NavLink>
          </form>
        </div>
      </div>
      <div className="right-content">
        <div className="auth-animation">
          <Lottie animationData={animation} />
        </div>
      </div>
    </div>
  );
};

export default Forgot;
