import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { arrowLeft } from "../../../../common/images";
import PrimaryButton from "../../../../common/primaryButton/PrimaryButton";
import Lottie from "lottie-react";
import animation from "../../../../assets/animations/authAnimation.json";
import "./EmailVerify.scss";
import AuthCode from "react-auth-code-input";
import { emailVerifyService } from "../../../../services/signup-service";
import { toast } from "react-toastify";
import { errorMsg } from "../../login/helper";

const EmailVerify = ({ setStep, personalDetail }) => {
  const [optVal, setOtpVal] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleOtpChange = (val) => {
    setOtpVal(val);
    if (val?.length === 6) {
      isError && setIsError(false);
    }
  };

  const handleEmailVerify = async (e) => {
    e.preventDefault();
    if (optVal?.length < 6) {
      setIsError(true);
      return;
    }
    const body = {
      username: personalDetail?.emailAddress,
      password: personalDetail?.password,
      emailVerificationCode: optVal,
      bResendCode: false,
    };

    setLoading(true)
    const { status, error } = await emailVerifyService(body);
    setLoading(false)
    if (status) {
      setStep(5);
    }
    if(error&& error?.errors[0]?.messageCode ==='Invalid' ){
      toast.dismiss()
      toast.error('Invalid code')
    }
  };

  const handleResend = async (e) => {
    const body = {
      username: personalDetail?.emailAddress,
      password: personalDetail?.password,
      emailVerificationCode: "",
      bResendCode: true,
    };

    const { status, error } = await emailVerifyService(body);
    if (status) {
      toast.success("Code sent to your email address");
    }
    if(error){
      const err=error?.errors[0]?.messageCode
      toast.dismiss()
      toast.error(errorMsg[err]|| 'Something went wrong')
    }
  };

  return (
    <div className="auth-content forgot verify-email">
      <div className="left-content">
        <div className="content">
          <form>
            <h3>Email verification</h3>
            <p>Enter the code sent to your email address.</p>
            <div className="input">
              <label className="verification-code" htmlFor="text">
                Your verification code
              </label>
              <AuthCode
                containerClassName="otp-code"
                onChange={(res) => handleOtpChange(res)}
                allowedCharacters="numeric"
                placeholder="•"
                inputClassName={isError && "error"}
              />
              <a className="resend-text" href="#!" onClick={handleResend}>
                Resend code
              </a>
            </div>

            <PrimaryButton
              btnText={loading?"Loading...":"Continue"}
              noBtnIcon
              type={"button"}
              click={handleEmailVerify}
              disabled={loading}
            />
            <NavLink to="/login">
              <img src={arrowLeft} alt="" />
              Back to log in
            </NavLink>
          </form>
        </div>
      </div>
      <div className="right-content">
        <div className="auth-animation">
          <Lottie animationData={animation} />
        </div>
      </div>
    </div>
  );
};

export default EmailVerify;
