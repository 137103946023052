/* eslint-disable react-hooks/exhaustive-deps */
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import AuthCode from "react-auth-code-input";
import { NavLink, useLocation } from "react-router-dom";
import { arrowLeft, logo } from "../../../common/images";
import animation from "../../../assets/animations/authAnimation.json";
import PrimaryButton from "../../../common/primaryButton/PrimaryButton";
import "./Otp.scss";
import {
  loginSms2faCodeService,
  otpService,
} from "../../../services/login-service";
import { errorMsg, generateToken } from "../login/helper";
import { toast } from "react-toastify";
import { config } from "../../../config";

const Otp = ({ setStep }) => {
  const [optVal, setOtpVal] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { state } = useLocation();
  const details = state?.detail || null;
  const loginData = state?.loginData || null;

  const handleOtpChange = (val) => {
    setOtpVal(val);
    if (val?.length === 6) {
      isError && setIsError(false);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (optVal?.length < 6) {
      setIsError(true);
      return;
    }

    const body = {
      ...loginData,
      tfaCode: optVal,
      tfaType: details?.bTwoFactorSMSAuthEnabled ? "SMS" : "AuthenticatorApp",
    };
    setIsLoading(true);
    const { status, data, error } = await otpService(body);
    setIsLoading(false);
    if (status) {
      const detail = {
        loginResponseDetails: data?.details,
      };
      const token = generateToken(
        detail,
        `${config.redirectUrl}access`
      );
      const a = document.createElement("a");
      a.href = `${config.redirectUrl}access?token=${token}`;
      a.target='_blank'
      a.click()
    }
    if (error) {
      const err = error?.errors[0]?.messageCode;
      toast.dismiss();
      toast.error(errorMsg[err] || "Something went wrong");
    }
  };

  const handleSMSOtp = async () => {
    const body = {
      bNewPhoneNumber: false,
      username: loginData.username,
      password: loginData.password,
    };
    await loginSms2faCodeService(body);
  };

  useEffect(() => {
    if (details?.bTwoFactorSMSAuthEnabled) {
      handleSMSOtp();
    }
  }, [details]);

  return (
    <div className="otpClass">
      <div className="left-content">
        <div className="content">
          <div className="header">
            <NavLink to="/">
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <form>
            <h3>Two Factor Authentication</h3>
            <p>
              {details?.bTwoFactorSMSAuthEnabled
                ? "Enter the code sent to your phone number"
                : "Enter the code from your authentication app."}
            </p>
            <p className="verification-code">Your verification code</p>
            <AuthCode
              containerClassName="otp-code"
              onChange={(res) => handleOtpChange(res)}
              allowedCharacters="numeric"
              inputClassName={isError && "error"}
              placeholder="•"
            />

            <PrimaryButton
              btnText={isLoading ? "Loading..." : "Continue"}
              disabled={isLoading}
              click={handleClick}
            />
            <h5>
              <NavLink to="/login">
                <img src={arrowLeft} alt="" />
                Back to log in
              </NavLink>
            </h5>
          </form>
          <h6>© {new Date().getFullYear()} Ibanera. All rights reserved.</h6>
        </div>
      </div>
      <div className="right-content">
        <div className="auth-animation">
          <Lottie animationData={animation} />
        </div>
      </div>
    </div>
  );
};

export default Otp;
