import React, { useEffect, useState } from "react";
import AccountTypeMain from "./accountType";

import "./Signup.scss";
import "./layout/Layout.scss";
import PersonalDetailMain from "./personalDetailForm";
import EmailVerify from "./emailVerify/EmailVerify";
import Select2FAType from "./select2faType";
import TwoFactor from "./select2faType/TwoFactor";
import Sms from "./select2faType/Sms";
import ThankYou from "./layout/ThankYou";
import Enable2FaOtp from "../signup/enable2fa";
import { getCountryAndProductService } from "../../../services/signup-service";

const SignUp = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    personalDetail: null,
    details: null,
  });

  const [countryList, setCountry] = useState([]);
  const [productServicesList, setProductServicesList] = useState([]);

  const fetchCountryAndEntity = async () => {
    const { data, status } = await getCountryAndProductService();
    if (status) {
      const countries = data?.details?.countries?.map((item) => {
        return {
          label: item.name,
          value: item?.isoCode,
        };
      });
      const productServices = data?.details?.productServices?.map((item) => ({
        label: item.name,
        value: item?.id,
      }));
      setCountry(countries);
      setProductServicesList(productServices);
    }
  };

  useEffect(() => {
    fetchCountryAndEntity();
  }, []);


  return (
    <>
      {step === 1 && (
        <AccountTypeMain
          setStep={setStep}
          countryList={countryList}
          setFormData={setFormData}
          productServicesList={productServicesList}
        />
      )}
      {step === 2 && (
        <PersonalDetailMain
          setStep={setStep}
          details={formData.details}
          setFormData={setFormData}
        />
      )}

      {step === 4 && (
        <EmailVerify
          setStep={setStep}
          personalDetail={formData?.personalDetail}
        />
      )}
      {step === 5 && (
        <Select2FAType setStep={setStep} setFormData={setFormData} />
      )}
      {step === 6 && (
        <TwoFactor
          setStep={setStep}
          personalDetail={formData?.personalDetail}
          setFormData={setFormData}
        />
      )}
      {step === 7 && (
        <Sms
          setStep={setStep}
          personalDetail={formData?.personalDetail}
          setFormData={setFormData}
        />
      )}
      {step === 8 && (
        <Enable2FaOtp setStep={setStep} details={formData?.personalDetail} />
      )}
      {step === 9 && <ThankYou />}
    </>
  );
};

export default SignUp;
