import * as yup from "yup";

export const personalDetailSchema = yup
  .object({
    firstName: yup.string().required("First name is required"),
    middleNames: yup.string(),
    lastName: yup.string().required("Last name is required"),
    emailAddress: yup
      .string()
      .required("Email is required")
      .email("Invalid email"),
    phoneNumber: yup.string().required("Phone number is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(/[aA-zZ]+/, "Password must contain a letter")
      .matches(/\d+/, "Password must contain a number"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();
