import React from "react";
import "./SecondaryButton.scss";

const SecondaryButton = ({
  btnIcon,
  btnText,
  btnClass,
  click,
  externalLink,
}) => {
  const handleExternalClick = () => {
    window.open(externalLink, "_blank");
  };
  return (
    <button
      className={`secondary-button ${btnClass ? btnClass : ""}`}
      onClick={externalLink ? handleExternalClick : click}
    >
      {btnText}
      {btnIcon && <span>{btnIcon}</span>}
    </button>
  );
};

export default SecondaryButton;
