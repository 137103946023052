import React from "react";
import "./PrimaryButton.scss";

const PrimaryButton = ({
  btnText,
  btnClass,
  btnIcon,
  noBtnIcon,
  click,
  type,
  disabled,
  externalLink,
  style
}) => {
  const handleExternalClick = () => {
    window.open(externalLink, "_blank");
  };

  return (
    <button
      type={type}
      className={`primary-button ${btnClass ? btnClass : ""} ${
        disabled ? "disabled" : ""
      }`}
      onClick={externalLink ? handleExternalClick : click}
      disabled={disabled}
      style={{...style}}
    >
      {btnText}{" "}
      {!noBtnIcon ? (
        <span className="first-btn">
          {btnIcon ? (
            btnIcon
          ) : (
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.3335 8.20036H12.6668M12.6668 8.20036L8.00016 3.53369M12.6668 8.20036L8.00016 12.867"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </span>
      ) : (
        ""
      )}
    </button>
  );
};

export default PrimaryButton;
