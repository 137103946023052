import * as yup from "yup";

export const loginSchema = yup
  .object({
    username: yup.string().required("Email is required").email("Invalid email"),
    password: yup.string().required("Password is required"),
    rememberMe: yup.boolean(false),
  })
  .required();

export const errorMsg = {
  Invalid: "Invalid code",
  Expired: "Code expired",
  Username_Or_Password_Incorrect: "Username or password is incorrect",
  Account_Locked:
    "Account locked for 10 minutes after too many failed attempts",
  Account_Suppressed: "Account is currently inactive",
  EmailAddress_Already_Verified:
    "Email address is already verified. User can proceed to the next step",
};

export const generateToken = (loginResponseDetails, redirectUrl) => {
  return encodeURIComponent(
    btoa(JSON.stringify(loginResponseDetails, redirectUrl))
  );
};
