/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../../common/primaryButton/PrimaryButton";
import Lottie from "lottie-react";
import animation from "../../../../assets/animations/authAnimation.json";
import { NavLink } from "react-router-dom";
import { logo } from "../../../../common/images";
import { getQrCodeService } from "../../../../services/signup-service";
import { toast } from "react-toastify";
import { errorMsg } from "../../login/helper";

const TwoFactor = ({ setStep, personalDetail, setFormData }) => {
  const [qrData, setQrData] = useState(null);

  const handleFetchQr = async () => {
    const body = {
      username: personalDetail?.emailAddress,
      password: personalDetail?.password,
    };
    const { status, data, error } = await getQrCodeService(body);

    if (status) {
      setQrData(data.details);

      setFormData((prev) => ({
        ...prev,
        personalDetail: {
          ...prev.personalDetail,
          totpSharedSecret: data.details?.sharedSecret,
        },
      }));
    }
    if(error){
      const err=error?.errors[0]?.messageCode
      toast.dismiss()
      toast.error(errorMsg[err]|| 'Something went wrong') 
    }
  };

  useEffect(() => {
    if (personalDetail.emailAddress) {
      handleFetchQr();
    }
  }, [personalDetail.emailAddress]);

  return (
    <div className="auth-content business-form two-factor">
      <div className="left-content">
        <div className="content">
          <div className="header">
            <NavLink to="/">
              <img src={logo} alt="logo" />
            </NavLink>
            <form action="">
              <h3>Two-Factor Authentication</h3>
              <p>
                To setup 2FA scan tha QR code below using your authenticator
                app.
              </p>
              <div
                style={{
                  height: "150px",
                  margin: "0 auto",
                  maxWidth: 150,
                  width: "100%",
                  marginTop: "60px",
                  marginBottom: "30px",
                }}
              >
                {qrData && (
                  <img
                    src={`data:image/png;base64,${qrData?.qrCode}`}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                )}
              </div>
              {qrData && (
                <p style={{ textAlign: "center" }}>{qrData?.sharedSecret}</p>
              )}
              <PrimaryButton
                btnText="Continue"
                noBtnIcon
                btnClass="full"
                disabled={!qrData}
                click={() => setStep(8)}
              />
            </form>
            <span className="instead-class" onClick={() => setStep(7)}>
              Use SMS instead
            </span>
          </div>
        </div>
      </div>
      <div className="right-content">
        <div className="auth-animation">
          <Lottie animationData={animation} />
        </div>
      </div>
    </div>
  );
};

export default TwoFactor;
