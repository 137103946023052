import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../../common/primaryButton/PrimaryButton";
import { NavLink } from "react-router-dom";
import { logo } from "../../../../common/images";
import Lottie from "lottie-react";
import animation from "../../../../assets/animations/authAnimation.json";
import PhoneInput from "react-phone-number-input";
import { sms2faCodeService } from "../../../../services/signup-service";
import { toast } from "react-toastify";
import { errorMsg } from "../../login/helper";

const Sms = ({ setStep, personalDetail, setFormData }) => {
  const [phoneVal, setPhoneVal] = useState(personalDetail?.phoneNumber || "");
  const [isLoading, setIsLoading]=useState(false)


  const handleSMSOtp = async (e) => {
    const body = {
      username: personalDetail?.emailAddress,
      password: personalDetail?.password,
      phoneNumber: phoneVal,
      bNewPhoneNumber: true,
    };
    setIsLoading(true);
    const { status, error } = await sms2faCodeService(body);
    setIsLoading(false);
    if (status) {
      setStep(8)
      setFormData((prev) => ({
        ...prev,
        personalDetail: {
          ...prev.personalDetail,
          phoneNumber: phoneVal,
        },
      }));
    }
    if(error){
      const err=error?.errors[0]?.messageCode
      toast.dismiss()
      toast.error(errorMsg[err]|| 'Something went wrong')
    }
  };



  useEffect(() => {
    if (personalDetail) {
      setPhoneVal(personalDetail?.phoneNumber || "");
    }
  }, [personalDetail]);

  return (
    <div className="auth-content business-form two-factor">
      <div className="left-content">
        <div className="content">
          <div className="header sms-auth">
            <NavLink to="/">
              <img src={logo} alt="logo" />
            </NavLink>
            <form action="">
              <h3>Two-Factor Authentication</h3>
              <p>Enter your phone number to receive your code.</p>

              <div
                // className={`select-phone ${
                //   errors?.phoneNumber && "error-input"
                // }`}
                className="select-phone"
              >
                {/* <Controller
                  name="phoneNumber"
                  control={""}
                  render={({ field: { onChange, value } }) => ""}
                /> */}
                <PhoneInput
                  value={phoneVal}
                  onChange={(val) => {
                    setPhoneVal(val);
                  }}
                  defaultCountry="US"
                  id="phone-input"
                />
                {/* {errors.phoneNumber?.message && (
                  <span className="error">{errors.phoneNumber?.message}</span>
                )} */}
              </div>

              <PrimaryButton
                btnText={isLoading?"Loading...":"Continue"}
                noBtnIcon
                btnClass="full"
                disabled={isLoading}
                click={handleSMSOtp}
              />
            </form>
            <span className="instead-class" onClick={() => setStep(6)}>
              Use Auth instead
            </span>
          </div>
        </div>
      </div>
      <div className="right-content">
        <div className="auth-animation">
          <Lottie animationData={animation} />
        </div>
      </div>
    </div>
  );
};

export default Sms;
