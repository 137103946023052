import axios from "axios";
import { config } from "../config";

export const loginService = async (body) => {
  try {
    const res = await axios.post(
      config.customerBaseUrl + "publicrole/authmodule/login",
      body
    );

    return { status: true, data: res.data };
  } catch (error) {
    return { status: false, error: error?.response?.data };
  }
};

export const loginSms2faCodeService = async (body) => {
  try {
    const res = await axios.post(
      config.customerBaseUrl +
        "publicrole/authmodule/requestsmstfacode",
      body
    );

    return { status: true, data: res.data };
  } catch (error) {
    return { status: false, error: error?.response?.data };
  }
};


export const otpService = async (body) => {
  try {
    const res = await axios.post(
      config.customerBaseUrl + "publicrole/authmodule/tfalogin",
      body
    );

    return { status: true, data: res.data };
  } catch (error) {
    return { status: false, error: error?.response?.data };
  }
};



export const forgotService = async (emailAddress) => {
  try {
    const res = await axios.post(
      config.customerBaseUrl + `publicrole/forgottenpasswordmodule/requestpasswordreset?EmailAddress=${encodeURIComponent(emailAddress)}`,
    );

    return { status: true, data: res.data };
  } catch (error) {
    return { status: false, error: error?.response?.data };
  }
};

export const resetPasswordService = async (body) => {
  try {
    const res = await axios.post(
      config.customerBaseUrl + `publicrole/forgottenpasswordmodule/resetpassword`,
      body
    );

    return { status: true, data: res.data };
  } catch (error) {
    return { status: false, error: error?.response?.data };
  }
};