import "./App.scss";
import React, { useState, Suspense, lazy, useEffect } from "react";
import Footer from "./layout/footer/Footer";
import Header from "./layout/header/Header";
import { Routes, Route, useLocation } from "react-router-dom";
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import SkeletonLoader from "./common/loader/Loader";
import "aos/dist/aos.css";
import AOS from "aos";
import Login from "./pages/auth/login/Login";
import SignUp from "./pages/auth/signup/SignUp";
import Forgot from "./pages/auth/forgot/Forgot";
import CheckMail from "./pages/auth/forgot/CheckMail";
import ResetPass from "./pages/auth/forgot/ResetPass";
import EmailVerify from "./pages/auth/signup/emailVerify/EmailVerify";
import Otp from "./pages/auth/otp/Otp";


const Home = lazy(() => import("./pages/home/sections/Home"));
const About = lazy(() => import("./pages/about/About"));
const Developer = lazy(() => import("./pages/developer/Developer"));
const Career = lazy(() => import("./pages/career/Career"));
const BecomePartner = lazy(() => import("./pages/becomePartner/BecomePartner"));
const Blog = lazy(() => import("./pages/blog/Blog"));
const Terms = lazy(() => import("./pages/terms/Terms"));
const Privacy = lazy(() => import("./pages/privacy/Privacy"));
const Refund = lazy(() => import("./pages/refund/Refund"));
const Fees = lazy(() => import("./pages/fees/Fees"));
const Contact = lazy(() => import("./pages/contact/Contact"));
const Services = lazy(() => import("./pages/services/Services"));
// const SignUp = lazy(() => import("./pages/auth/signup/SignUp"));
// const Forgot = lazy(() => import("./pages/auth/forgot/Forgot"));
// const CheckMail = lazy(() => import("./pages/auth/forgot/CheckMail"));
// const ResetPass = lazy(() => import("./pages/auth/forgot/ResetPass"));
const NotFound = lazy(() => import("./pages/notFound/NotFound"));
const Detail = lazy(() => import("./pages/blog/detail/Detail"));
// const EmailVerify = lazy(() =>
//   import("./pages/auth/signup/emailVerify/EmailVerify")
// );
// const Otp = lazy(() => import("./pages/auth/otp/Otp"));
// const Login = lazy(() => import("./pages/auth/login/Login"));
const Regulatory = lazy(() => import("./pages/regulatory/Regulatory"));
const ApprovedUrl = lazy(() => import("./pages/approvedUrl"));
const Agreement = lazy(() => import("./pages/agreements"));
const DigitalCustody = lazy(() => import("./pages/digitalCustody"));
const TermsUsa = lazy(() => import("./pages/termsUsa"));
const TermsEurope = lazy(() => import("./pages/termsEur"));
const TermsSingapore = lazy(() => import("./pages/termsSingapore"));
const CookiesPopup = lazy(() =>
  import("./components/cookiesPopup/CookiesPopup")
);
const Cookies = lazy(() => import("./pages/cookie/Cookies"));

function App() {
  const [overlay, setOverlay] = useState(false);
  const location = useLocation();
  useEffect(() => {
    AOS.init({
      once: false,
    });
  }, []);

  const analytics = Analytics({
    app: "ibanera-website",
    plugins: [
      googleAnalytics({
        measurementIds: ["G-H9WM8034HT"],
      }),
    ],
  });

  analytics.page();

  const isAuthPage = [
    "/signup",
    "/login",
    "/forgot",
    "/check-email",
    "/en-US/reset-password",
    "/verify-email",
    "/otp",
    "/authenticator",
  ].includes(location.pathname);

  return (
    <>
      <CookiesPopup />
      {isAuthPage ? (
        // <Suspense fallback={<SkeletonLoader />}>
        <Routes>
          <Route path="/otp" element={<Otp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/check-email" element={<CheckMail />} />
          <Route path="/en-US/reset-password" element={<ResetPass />} />
          <Route path="/verify-email" element={<EmailVerify />} />
        </Routes>
      ) : (
        // </Suspense>
        <>
          <Header setOverlay={setOverlay} />
          <div className={`main-content ${overlay ? "overlay" : ""}`}>
            <Suspense fallback={<SkeletonLoader />}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/developer" element={<Developer />} />
                <Route path="/career" element={<Career />} />
                <Route path="/partner" element={<BecomePartner />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog-detail/:id" element={<Detail />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/cookie-policy" element={<Cookies />} />
                <Route path="/approved-urls" element={<ApprovedUrl />} />
                <Route path="/legal-agreements" element={<Agreement />} />
                <Route path="/regulatory" element={<Regulatory />} />
                <Route path="/refund" element={<Refund />} />
                <Route path="" element={<Fees />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/services" element={<Services />} />
                <Route
                  path="/digital-asset-custody-terms-conditions"
                  element={<DigitalCustody />}
                />
                <Route
                  path="/usa-general-terms-conditions"
                  element={<TermsUsa />}
                />
                <Route
                  path="/europe-general-terms-conditions"
                  element={<TermsEurope />}
                />
                <Route
                  path="/singapore-general-terms-conditions"
                  element={<TermsSingapore />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
