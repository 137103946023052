import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import { eye, eyeLock } from "../../../../common/images";
import PrimaryButton from "../../../../common/primaryButton/PrimaryButton";
import { NavLink } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { personalDetailSchema } from "./helper";
import { businessDetailService } from "../../../../services/signup-service";
import { toast } from "react-toastify";

const PersonalForm = ({ setStep, setFormData, details }) => {
  const [isShow, setIsShow] = useState(false);
  const [isConfirmShow, setIsConfirmShow] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm({
    resolver: yupResolver(personalDetailSchema),
  });

  const onSubmitForm = async (values) => {
    if (!isValidPhoneNumber(values?.phoneNumber)) {
      setError("phoneNumber", { message: "Invalid Phone Number" });
      return;
    }
    const body = {
      ...values,
      currencyCode: "USD",
      nameConfirmed: true,
    };

    setLoading(true);
    const { status, error } = await businessDetailService({
      personalDetails: body,
      ...details,
    });
    setLoading(false);
    if (status) {
      setFormData((prev) => ({ ...prev, personalDetail: body }));
      setStep(4);
    } else {
      if (error && error?.errors[0]?.messageCode === "Already_In_Use") {
        toast.dismiss();
        toast.error("Email already in use");
        // setError("emailAddress", { message: "Email already in use" });
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="Name">
          <div>
            <label className="form-label">
              First Name <span>*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errors?.firstName && "error-input"}`}
              placeholder="First name"
              {...register("firstName")}
            />
            {errors.firstName?.message && (
              <span className="error">{errors.firstName?.message}</span>
            )}
          </div>
          <div>
            <label className="form-label">Middle Name</label>
            <input
              className={`form-control`}
              type="text"
              placeholder="Middle name"
              {...register("middleNames")}
            />
          </div>
          <div>
            <label className="form-label">
              Last Name <span>*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errors?.lastName && "error-input"}`}
              placeholder="Last name"
              {...register("lastName")}
            />
            {errors.lastName?.message && (
              <span className="error">{errors.lastName?.message}</span>
            )}
          </div>
        </div>
        <div className="email">
          <label className="form-label">
            Email <span>*</span>
          </label>
          <input
            type="email"
            className={`form-control ${errors?.emailAddress && "error-input"}`}
            placeholder="you@company.com"
            {...register("emailAddress")}
          />
          {errors.emailAddress?.message && (
            <span className="error">{errors.emailAddress?.message}</span>
          )}
        </div>
        <div className={`select-phone ${errors?.phoneNumber && "error-input"}`}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                value={value}
                onChange={onChange}
                defaultCountry="US"
                id="phone-input"
              />
            )}
          />
          {errors.phoneNumber?.message && (
            <span className="error">{errors.phoneNumber?.message}</span>
          )}
        </div>
        <div className="password main-pass">
          <label className="form-label">
            Password <span>*</span>
          </label>
          <input
            className={`form-control ${errors?.password && "error-input"}`}
            type={isShow ? "text" : "password"}
            placeholder="Create a password"
            {...register("password")}
          />
          {errors.password?.message && (
            <span className="error">{errors.password?.message}</span>
          )}

          {!isShow ? (
            <img
              className="pass-eye"
              src={eye}
              alt=""
              onClick={() => setIsShow(true)}
            />
          ) : (
            <img
              className="pass-eye"
              src={eyeLock}
              alt=""
              onClick={() => setIsShow(false)}
            />
          )}
        </div>
        <div className="password main-pass">
          <label className="form-label">
            Confirm password <span>*</span>
          </label>
          <input
            className={`form-control ${
              errors?.confirmPassword && "error-input"
            }`}
            type={isConfirmShow ? "text" : "password"}
            placeholder="Re-type password"
            {...register("confirmPassword")}
          />
          {errors.confirmPassword?.message && (
            <span className="error">{errors.confirmPassword?.message}</span>
          )}
          {!isConfirmShow ? (
            <img
              className="pass-eye"
              src={eye}
              alt=""
              onClick={() => setIsConfirmShow(true)}
            />
          ) : (
            <img
              className="pass-eye"
              src={eyeLock}
              alt=""
              onClick={() => setIsConfirmShow(false)}
            />
          )}
        </div>
        <PrimaryButton
          type={"submit"}
          btnClass="full"
          btnText={isLoading ? "Loading..." : "Get started"}
          noBtnIcon
          disabled={isLoading}
        />
        <h5 style={{ width: "100%" }}>
          Already have an account? <NavLink to="/login">Log in</NavLink>
        </h5>
      </form>
    </div>
  );
};

export default PersonalForm;
