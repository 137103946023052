import React, { useState } from "react";
import PrimaryButton from "../../../../common/primaryButton/PrimaryButton";
import { NavLink } from "react-router-dom";
import { authAppImg, logo, smsImage } from "../../../../common/images";
import "./style.scss";
import Lottie from "lottie-react";
import animation from "../../../../assets/animations/authAnimation.json";

const Select2FAType = ({ setStep, setFormData }) => {
  const [selected, setSelected] = useState("AuthenticatorApp");

  const handleSelectCard = (val) => {
    setSelected(val);
    setFormData((prev) => ({
      ...prev,
      personalDetail: { ...prev.personalDetail, tfaType: val },
    }));
  };

  return (
    <>
      <div className="auth-content business-form authentication-screen">
        <div className="left-content">
          <div className="content">
            <div className="header">
              <NavLink to="/">
                <img src={logo} alt="logo" />
              </NavLink>
              <form action="">
                <h3>Add an extra layer of security</h3>
                <p>Secure your auth by adding two step verification</p>
                <div className="cards account-type">
                  <div
                    className={`card ${
                      selected === "AuthenticatorApp" ? "active" : ""
                    }`}
                    onClick={() => handleSelectCard("AuthenticatorApp")}
                  >
                    <div className="Heading">
                      <img src={authAppImg} alt="auth-img" />
                      <div className="left">
                        <h4>Authenticator App</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`card ${selected === "SMS" ? "active" : ""}`}
                    onClick={() => handleSelectCard("SMS")}
                  >
                    <div className="Heading">
                      <img src={smsImage} alt="auth-img" />
                      <div className="left">
                        <h4>SMS</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <PrimaryButton
                  btnText="Continue"
                  noBtnIcon
                  btnClass="full"
                  click={() => setStep(selected === "SMS" ? 7 : 6)}
                />
              </form>
            </div>
          </div>
        </div>
        <div className="right-content">
          <div className="auth-animation">
            <Lottie animationData={animation} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Select2FAType;
