import React from "react";
import Lottie from "lottie-react";
import { NavLink } from "react-router-dom";

import PersonalForm from "./form";

import { logo } from "../../../../common/images";
import animation from "../../../../assets/animations/authAnimation.json";

const PersonalDetailMain = ({setStep, setFormData, details}) => {
  return (
    <div className="auth-content ibanera-signup">
      <div className="left-content">
        <div className="content">
          <div className="header">
            <NavLink to="/">
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <div className="signup-form">
            <h3>Sign up</h3>
            <p>Open your account today.</p>
            <PersonalForm setStep={setStep} setFormData={setFormData} details={details}/>
          </div>

          <h6>© {new Date().getFullYear()} Ibanera. All rights reserved.</h6>
        </div>
      </div>
      <div className="right-content">
        <div className="auth-animation">
          <Lottie animationData={animation} />
        </div>
      </div>
    </div>
  );
};

export default PersonalDetailMain;
