import React from "react";
import PrimaryButton from "../../../../common/primaryButton/PrimaryButton";
import Lottie from "lottie-react";
import animation from "../../../../assets/animations/authAnimation.json";
import { NavLink, useNavigate } from "react-router-dom";
import { logo } from "../../../../common/images";
import { scrollToTop } from "../../../../common/ScrollToTop";

const ThankYou = () => {
  const navigate = useNavigate();
  const navigateToLogin = () => {
    navigate("/login");
    scrollToTop();
  };

  return (
    <div className="auth-content business-form two-factor">
      <div className="left-content">
        <div className="content">
          <div className="header sms-auth">
            <NavLink to="/">
              <img src={logo} alt="logo" />
            </NavLink>
            <form action="">
              <h3>Thank you</h3>
              <p>
                Your registration was successful. Return to the login screen to
                sign in.
              </p>
              <PrimaryButton
                btnText="Login"
                noBtnIcon
                btnClass="full"
                click={navigateToLogin}
              />
            </form>
          </div>
        </div>
      </div>
      <div className="right-content">
        <div className="auth-animation">
          <Lottie animationData={animation} />
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
