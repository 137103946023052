import axios from "axios";
import { config } from "../config";

export const personalDetailService = async (body) => {
  try {
    const res = await axios.post(
      config.customerBaseUrl +
        "publicrole/registrationmodule/validatepersonaldetails",
      body
    );

    return { status: true, data: res.data };
  } catch (error) {
    return { status: false, error: error?.response?.data };
  }
};

export const businessDetailService = async (body) => {
  try {
    const res = await axios.post(
      config.customerBaseUrlV2 +
        "publicrole/registrationmodule/registerbusinessaccount",

      body
    );

    return { status: true, data: res.data };
  } catch (error) {
    return { status: false, error: error?.response?.data };
  }
};

export const emailVerifyService = async (body) => {
  try {
    const res = await axios.post(
      config.customerBaseUrl +
        "publicrole/authmodule/verifyemailaddress",
      body
    );

    return { status: true, data: res.data };
  } catch (error) {
    return { status: false, error: error?.response?.data };
  }
};

export const getQrCodeService = async (body) => {
  try {
    const res = await axios.post(
      config.customerBaseUrl +
        "publicrole/authmodule/requesttotpqrcode",
      body
    );

    return { status: true, data: res.data };
  } catch (error) {
    return { status: false, error: error?.response?.data };
  }
};

export const enable2faService = async (body) => {
  try {
    const res = await axios.post(
      config.customerBaseUrl +
        "publicrole/authmodule/enabletfa",
      body
    );

    return { status: true, data: res.data };
  } catch (error) {
    return { status: false, error: error?.response?.data };
  }
};

export const sms2faCodeService = async (body) => {
  try {
    const res = await axios.post(
      config.customerBaseUrl +
        "publicrole/authmodule/requestsmstfacode",
      body
    );

    return { status: true, data: res.data };
  } catch (error) {
    return { status: false, error: error?.response?.data };
  }
};

export const getCountryAndProductService = async () => {
  try {
    const res = await axios.get(
      config.customerBaseUrlV2 +
        "publicrole/registrationmodule/getregistrationdetails",
    );

    return { status: true, data: res.data };
  } catch (error) {
    return { status: false, error: error?.response?.data };
  }
};
