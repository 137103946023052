import React from "react";
import Lottie from "lottie-react";
import { NavLink } from "react-router-dom";
import SelectAccountType from "./accountType";
import { logo } from "../../../../common/images";
import animation from "../../../../assets/animations/authAnimation.json";

const AccountTypeMain = ({
  setStep,
  productServicesList,
  countryList,
  setFormData,
}) => {
  return (
    <div className="auth-content ibanera-signup">
      <div className="left-content">
        <div className="content">
          <div className="header">
            <NavLink to="/">
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <div className="signup-form">
            <h3>Sign up</h3>
            <p>Banking for entrepreneurs.</p>
            <SelectAccountType
              setStep={setStep}
              countryList={countryList}
              productServicesList={productServicesList}
              setFormData={setFormData}
            />
          </div>

          <h6>© {new Date().getFullYear()} Ibanera. All rights reserved.</h6>
        </div>
      </div>
      <div className="right-content">
        <div className="auth-animation">
          <Lottie animationData={animation} />
        </div>
      </div>
    </div>
  );
};

export default AccountTypeMain;
