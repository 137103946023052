import * as yup from "yup";

export const validationSchema = yup
  .object({
    registeredCountryCode: yup.string().required("Field is required"),
    requestedProducts: yup
      .array()
      .min(1, "Field is required")
      .required("Field is required"),
    bConductsThirdPartyPayments: yup.string().required("Field is required"),
  })
  .required();

export const thirdPartyPaymentOption = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];
