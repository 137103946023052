import React, { useState } from "react";
import Lottie from "lottie-react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import PrimaryButton from "../../../common/primaryButton/PrimaryButton";

import logo from "../../../assets/images/home/logo.svg";
import animation from "../../../assets/animations/authAnimation.json";

import "./Login.scss";
import { errorMsg, loginSchema } from "./helper";
import { eye, eyeLock } from "../../../common/images";
import { loginService } from "../../../services/login-service";
import { toast } from "react-toastify";



const Login = () => {
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });
  
  const onSubmitForm = async (values) => {
    setIsLoading(true);
    const { status, data, error } = await loginService(values);
    setIsLoading(false);
    if (status) {
      if (data?.details) {
        navigate("/otp", {
          state: { detail: {...data?.details}, loginData:{...values} },
        });
      }
    }
    if(error){
     const err=error?.errors[0]?.messageCode
     toast.dismiss()
     toast.error(errorMsg[err]|| 'Something went wrong')
    }
  };

  return (
    <div className="auth-content">
      <div className="left-content">
        <div className="content">
          <div className="header">
            <NavLink to="/">
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <h3>Welcome back</h3>
            <p>Welcome back! Please enter your details.</p>

            <div className="input">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="text"
                placeholder="Enter your email"
                {...register("username")}
                className={errors?.username && "error-input"}
              />
              {errors.username?.message && (
                <span className="error">{errors.username?.message}</span>
              )}
            </div>

            <div className="input">
              <label htmlFor="pass">Password</label>
              <input
                id="pass"
                type={isShow ? "text" : "password"}
                placeholder="••••••••"
                {...register("password")}
                className={errors?.password && "error-input"}
              />
              {errors.password?.message && (
                <span className="error">{errors.password?.message}</span>
              )}
              {!isShow ? (
                <img
                  className="pass-eye"
                  src={eye}
                  alt=""
                  onClick={() => setIsShow(true)}
                />
              ) : (
                <img
                  className="pass-eye"
                  src={eyeLock}
                  alt=""
                  onClick={() => setIsShow(false)}
                />
              )}
            </div>

            <div className="form-check">
              <div>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="loginCheck"
                  {...register("rememberMe")}
                />
                <label className="form-check-label" htmlFor="loginCheck">
                  Remember for 30 days
                </label>
              </div>
              <NavLink to="/forgot">Forgot password</NavLink>
            </div>
            <PrimaryButton
              btnText={isLoading ? "Signing..." : "Sign in"}
              noBtnIcon
              disabled={isLoading}
            />
            <h5>
              Don’t have an account? <NavLink to="/signup">Sign up</NavLink>
            </h5>
          </form>

          <h6>© {new Date().getFullYear()} Ibanera. All rights reserved.</h6>
        </div>
      </div>
      <div className="right-content">
        <div className="auth-animation">
          <Lottie animationData={animation} />
        </div>
      </div>
    </div>
  );
};

export default Login;
