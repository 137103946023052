import Lottie from "lottie-react";
import React, { useState } from "react";
import AuthCode from "react-auth-code-input";
import { NavLink } from "react-router-dom";

import "./enable.scss";
import {
  enable2faService,
  sms2faCodeService,
} from "../../../../services/signup-service";
import { logo } from "../../../../common/images";
import PrimaryButton from "../../../../common/primaryButton/PrimaryButton";
import animation from "../../../../assets/animations/authAnimation.json";
import { toast } from "react-toastify";
import { errorMsg } from "../../login/helper";

const Enable2FaOtp = ({ setStep, details }) => {
  const [optVal, setOtpVal] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOtpChange = (val) => {
    setOtpVal(val);
    if (val?.length === 6) {
      isError && setIsError(false);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (optVal?.length < 6) {
      setIsError(true);
      return;
    }

    const body = {
      username: details?.emailAddress,
      password: details?.password,
      tfaType: details?.tfaType,
      tfaCode: optVal,
      phoneNumber: details?.tfaType === "SMS" ? details?.phoneNumber : "",
      totpSharedSecret: details?.totpSharedSecret,
    };
    setIsLoading(true);
    const { status, error } = await enable2faService(body);
    setIsLoading(false);
    if (status) {
      setStep(9);
    }
    if (error) {
      const err = error?.errors[1]?.messageCode|| error?.errors[0]?.messageCode;
      toast.dismiss();
      toast.error(errorMsg[err] || "Something went wrong");
    }
  };

  const handleResendSMSOtp = async (e) => {
    const body = {
      username: details?.emailAddress,
      password: details?.password,
      phoneNumber: details?.phoneNumber,
      bNewPhoneNumber: true,
    };
    const { status } = await sms2faCodeService(body);
    if (status) {
      toast.dismiss();
      toast.info(`Verification code sent to ${details?.phoneNumber}`);
    }
  };

  return (
    <div className="enable2faClass">
      <div className="left-content">
        <div className="content">
          <div className="header">
            <NavLink to="/">
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <form>
            <h3>Two Factor Authentication</h3>
            <p>
              {details?.tfaType === "SMS"
                ? "Enter the code sent to your phone number"
                : "Enter the code from your authentication app."}
            </p>
            <div style={{ display: "flex ", justifyContent: "space-between" }}>
              <p className="verification-code">Your verification code</p>
              {details?.tfaType === "SMS" && (
                <p
                  className="verification-code"
                  href="#!"
                  onClick={handleResendSMSOtp}
                  style={{ cursor: "pointer" }}
                >
                  Resend code
                </p>
              )}
            </div>
            <AuthCode
              containerClassName="otp-code"
              onChange={(res) => handleOtpChange(res)}
              allowedCharacters="numeric"
              inputClassName={isError && "error"}
              placeholder="•"
            />

            <PrimaryButton
              btnText={isLoading ? "Loading..." : "Continue"}
              disabled={isLoading}
              click={handleClick}
            />
          </form>
          <h6>© {new Date().getFullYear()} Ibanera. All rights reserved.</h6>
        </div>
      </div>
      <div className="right-content">
        <div className="auth-animation">
          <Lottie animationData={animation} />
        </div>
      </div>
    </div>
  );
};

export default Enable2FaOtp;
