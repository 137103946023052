import React, { useState } from "react";
import PrimaryButton from "../../../common/primaryButton/PrimaryButton";
import { NavLink, useNavigate } from "react-router-dom";
import {
  arrowLeft,
  checkIcon,
  eye,
  eyeLock,
  unCheckIcon,
} from "../../../common/images";
import Lottie from "lottie-react";
import animation from "../../../assets/animations/authAnimation.json";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordSchema } from "./helper";
import { toast } from "react-toastify";
import { resetPasswordService } from "../../../services/login-service";

const ResetPass = () => {
  const [isShow, setIsShow] = useState(false);
  const [isConfirmShow, setIsConfirmShow] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  const password = watch("newPassword");

  const onSubmitForm = async (values) => {

    const body = {
      ...values,
      passwordResetToken: encodeURIComponent(urlParams.get("token")),
    };

    setLoading(true);
    const { status, error } = await resetPasswordService({
      ...body,
    });
    setLoading(false);
    if (status) {
      toast.dismiss();
      toast.success("Your password has been successfully reset");
      navigate("/login");
    } else {
      if (
        error &&
        error?.errors[0]?.messageCode === "Password_Reset_Token_Invalid"
      ) {
        toast.dismiss();
        toast.error("The reset token provided is invalid");
      }
      if (
        error &&
        error?.errors[0]?.messageCode === "Password_Reset_Token_Expired"
      ) {
        toast.dismiss();
        toast.error("The reset token provided has expired");
      }
      if (
        error?.errors[0]?.fieldName === "PasswordResetToken" &&
        error?.errors[0]?.messageCode === "Required"
      ) {
        toast.dismiss();
        toast.error("Reset token is required");
      }
    }
  };

  return (
    <div className="auth-content forgot reset">
      <div className="left-content">
        <div className="content">
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <h3>Set new password</h3>
            <p>
              Your new password must be different to previously used passwords.
            </p>
            <div className="input">
              <label htmlFor="pass">Password</label>
              <input
                id="pass"
                type={isShow ? "text" : "password"}
                placeholder="••••••••"
                {...register("newPassword")}
                className={`form-control ${
                  errors?.newPassword && "error-input"
                }`}
              />
              {errors.newPassword?.message && (
                <span className="error" style={{ textAlign: "left" }}>
                  {errors.newPassword?.message}
                </span>
              )}

              {!isShow ? (
                <img
                  className="pass-eye"
                  src={eye}
                  alt="eye-open"
                  onClick={() => setIsShow(true)}
                />
              ) : (
                <img
                  className="pass-eye"
                  src={eyeLock}
                  alt="eye-close"
                  onClick={() => setIsShow(false)}
                />
              )}
            </div>
            <div className="input">
              <label htmlFor="confirm">Confirm password</label>
              <input
                id="confirm"
                type={isConfirmShow ? "text" : "password"}
                placeholder="••••••••"
                {...register("confirmNewPassword")}
                className={`form-control ${
                  errors?.confirmNewPassword && "error-input"
                }`}
              />
              {errors.confirmNewPassword?.message && (
                <span className="error" style={{ textAlign: "left" }}>
                  {errors.confirmNewPassword?.message}
                </span>
              )}
              {!isConfirmShow ? (
                <img
                  className="pass-eye"
                  src={eye}
                  alt=""
                  onClick={() => setIsConfirmShow(true)}
                />
              ) : (
                <img
                  className="pass-eye"
                  src={eyeLock}
                  alt=""
                  onClick={() => setIsConfirmShow(false)}
                />
              )}
            </div>
            <ul>
              <li>
                <img
                  src={
                    !password ||
                    password?.length < 8 ||
                    errors?.newPassword?.message
                      ? unCheckIcon
                      : checkIcon
                  }
                  alt="icon"
                />
                <span>Must be at least 8 characters</span>
              </li>
            </ul>
            <PrimaryButton
              noBtnIcon
              disabled={isLoading}
              btnText={isLoading ? "Loading..." : "Reset password"}
            />
            <NavLink to="/login">
              <img src={arrowLeft} alt="" />
              Back to log in
            </NavLink>
          </form>
        </div>
      </div>
      <div className="right-content">
        <div className="auth-animation">
          <Lottie animationData={animation} />
        </div>
      </div>
    </div>
  );
};

export default ResetPass;
