import React from "react";
import PrimaryButton from "../../../../common/primaryButton/PrimaryButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./helper";
import FieldForm from "./accountTypeForm";
import AccountType from "./type";

import "./style.scss";

const SelectAccountType = ({
  setStep,
  productServicesList,
  countryList,
  setFormData,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    const temp = {
      requestedProducts: data?.requestedProducts?.map((item) => item.value),
      bConductsThirdPartyPayments:
        data?.bConductsThirdPartyPayments === "yes" ? true : false,
      registeredCountryCode: data.registeredCountryCode,
      accountType: "Business",
    };
    setFormData((prev) => ({ ...prev, details: temp }));
    setStep(2);
  };

  return (
    <>
      <form className="cards account-type" onSubmit={handleSubmit(onSubmit)}>
        <AccountType />
        <FieldForm
          errors={errors}
          control={control}
          setError={setError}
          setValue={setValue}
          countryList={countryList}
          productServicesList={productServicesList}
        />
        <PrimaryButton
          btnText={"Continue"}
          noBtnIcon
          btnClass="full"
          // click={() => handleClick()}
        />
      </form>
    </>
  );
};

export default SelectAccountType;
