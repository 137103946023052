import * as yup from "yup";

export const forgotSchema = yup
  .object({
    EmailAddress: yup
      .string()
      .required("Email is required")
      .email("Invalid email"),
  })
  .required();

export const resetPasswordSchema = yup
  .object({
    newPassword: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(/[aA-zZ]+/, "Password must contain a letter")
      .matches(/\d+/, "Password must contain a number"),
      confirmNewPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  })
  .required();
